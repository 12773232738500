<template>
  <Toast />
  <div class="head-btn">
    <h2>Comissionamento</h2>
    <div class="groupCadastros">
      <GroupbtnAdmin></GroupbtnAdmin>
    </div>
    <div class="d-grid gap-2 col-2" style="justify-items: self-end">
      <router-link to="/cadastro/comissionamento">
        <button class="btn btn-login" type="button">Comissionar</button>
      </router-link>
    </div>
  </div>
  <div class="card">
    <DataTable
      :value="products"
      v-model:selection="selectedProduct1"
      v-model:filters="filters"
      selectionMode="single"
      dataKey="serial_number"
      :paginator="true"
      :rows="50"
      class="paginator-custom"
      @rowSelect="onRowSelect"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 20, 50]"
      responsiveLayout="scroll"
      currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords}"
      filterDisplay="row"
    >
      <Column
        field="commissioning_time"
        header="Data de comissionamento"
        :sortable="true"
        style="max-width: 12rem"
        :showFilterMenu="false"
      >
        <template #body="{ data }">
          {{ data.commissioning_time }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            v-model="filterModel.value"
            type="text"
            @input="filterCallback()"
            class="p-column-filter"
            placeholder="Pesquise pelo Data"
          />
        </template>
      </Column>
      <Column
        field="meter_id"
        header="Código de Instalação"
        style="max-width: 15rem"
        :showFilterMenu="false"
      >
        <template #body="{ data }">
          {{ data.meter_id }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            v-model="filterModel.value"
            type="text"
            @input="filterCallback()"
            class="p-column-filter"
            placeholder="Pesquise pelo Cód."
          />
        </template>
      </Column>
      <Column
        field="serial_number"
        header="ID do Dispositivo"
        style="max-width: 15rem"
        :showFilterMenu="false"
      >
        <template #body="{ data }">
          {{ data.serial_number }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            v-model="filterModel.value"
            type="text"
            @input="filterCallback()"
            class="p-column-filter"
            placeholder="Pesquise pelo SN."
          />
        </template>
      </Column>
      <Column
        field="type_sensor"
        header="Tipo"
        :showFilterMenu="false"
        style="max-width: 10rem"
        :showClearButton="false"
      >
        <template #body="{ data }">{{ data.type_sensor }}</template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            @change="filterCallback()"
            :options="types"
            placeholder="Selecione "
            class="p-column-filter"
            style="max-width: 10rem"
            :showClear="true"
          >
            <template #option="slotProps">
              <span>{{ slotProps.option }}</span>
            </template>
          </Dropdown>
        </template>
      </Column>
      <Column
        field="address_install"
        header="Endereço de instalação"
        style="max-width: 15rem"
        :showFilterMenu="false"
      >
        <template #body="{ data }">{{
          data.address_install.replace(/null,|null-null/g, " ")
        }}</template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            v-model="filterModel.value"
            type="text"
            @input="filterCallback()"
            class="p-column-filter"
            placeholder="Digite o endereço"
          />
        </template>
      </Column>
      <Column
        field="initial_display"
        header="Display Inicial"
        style="max-width: 15rem"
        :showFilterMenu="false"
      >
        <template #body="{ data }">
          {{ data.initial_display }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            v-model="filterModel.value"
            type="text"
            @input="filterCallback()"
            class="p-column-filter"
            placeholder="Pesquise o número."
          />
        </template>
      </Column>
      <Column
        field="active"
        header="Ativado"
        :showFilterMenu="false"
        style="max-width: 9rem"
        :showClearButton="false"
      >
        <template #body="{ data }">{{ data.active }}</template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            @change="filterCallback()"
            :options="actives"
            placeholder="Selecione "
            class="p-column-filter"
            style="max-width: 9rem"
            :showClear="true"
          >
            <template #option="slotProps">
              <span>{{ slotProps.option }}</span>
            </template>
          </Dropdown>
        </template>
      </Column>
    </DataTable>
  </div>
  <Dialog
    header="Edição"
    class="dialog-custom"
    v-model:visible="displayBasic"
    :style="{ width: '75vw' }"
  >
    <div class="row" v-if="local_country == 'pt-BR'">
      <p style="font-size: 1rem">* Campos Obrigatórios</p>
      <div class="col-md-3">
        <div class="form-floating">
          <input
            type="text"
            class="form-control block"
            placeholder=" Hidrômetro"
            v-model="meter_id"
            aria-describedby="addon-wrapping"
            minlength="5"
            required
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Hidrômetro *</label>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            placeholder=" Display Inicial"
            v-model="initial_display"
            autocomplete="off"
            aria-describedby="addon-wrapping"
            minlength="5"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Display Inicial</label>
        </div>
      </div>
      <div class="col-sm-3 form-floating" style="flex: auto">
        <Multiselect
          :columns="{ container: 12, label: 4, wrapper: 6 }"
          v-model="resolution"
          placeholder="Selecione"
          class="multiselect-custom form-select"
          :options="resolutionTable"
          id="floatingInputGrid"
        />
        <label for="floatingSelect" style="margin-left: 15px; padding: 12px"
          >Fator Pulso</label
        >
      </div>
      <div class="col-md-3">
        <label style="font-size: 12px; margin-top: 18px">
          * Fator Pulso é a equivalencia de litros por pulso. Ex: 1 pulso
          equivale a (1 litro, 10 litros, 100 litros ou 1000 litros)
        </label>
      </div>
      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            placeholder="Cep"
            v-model="zipcode"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Cep</label>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            placeholder="Endereço"
            v-model="address"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Endereço</label>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            placeholder="Bairro"
            v-model="neighborhood"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Bairro</label>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            placeholder="Número"
            v-model="number"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Número</label>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            placeholder="Cidade"
            v-model="city"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Cidade</label>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            placeholder="Estado"
            v-model="state"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Estado</label>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            placeholder="País"
            v-model="country"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">País</label>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            placeholder="Altitude (metros)"
            v-model="alt"
            @input="formatAltitude"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Altitude (metros)</label>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            placeholder="Latitude"
            v-model="lat"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Latitude *</label>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            placeholder="Longitude"
            v-model="long"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Longitude *</label>
        </div>
      </div>
      <!-- <div class="col-sm-6">
        <Multiselect
          v-model="value"
          :columns="{ container: 12, label: 4, wrapper: 6 }"
          :placeholder="
            type == 'flow' ? 'Fluxo' : type == 'pressure' ? 'Pressão' : 'Ruído'
          "
          class="multiselect-custom"
          :options="tiposDispositivos"
        />
      </div> -->
      <div class="col-md-6 serial-number">
        <div>
          <span style="font-size: 25px">
            Dispositivo do tipo
            {{
              type_sensor == "flow"
                ? "Vazão"
                : type_sensor == "pressure"
                ? "Pressão"
                : "Pressão"
            }}
          </span>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            placeholder="ID do Dispositivo"
            v-model="serial_number"
            autocomplete="off"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">ID do Dispositivo *</label>
        </div>
      </div>
    </div>
    <!-- Verify Country -->
    <div class="row" v-else>
      <p style="font-size: 1rem">* Campos Obrigatórios</p>
      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control block"
            placeholder=" Hidrômetro"
            v-model="meter_id"
            aria-describedby="addon-wrapping"
            minlength="5"
            required
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Hidrômetro *</label>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            autocomplete="off"
            placeholder=" Display Inicial"
            v-model="initial_display"
            aria-describedby="addon-wrapping"
            minlength="5"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Display Inicial</label>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            placeholder="Código Postal"
            v-model="zipcode"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Código Postal</label>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            placeholder="Morada"
            v-model="address"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Morada</label>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            placeholder="Concelho"
            v-model="neighborhood"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Concelho</label>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            placeholder="Número"
            v-model="number"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Número</label>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            placeholder="Distrito"
            v-model="city"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Distrito</label>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            placeholder="Estado"
            v-model="state"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Estado</label>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            placeholder="País"
            v-model="country"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">País</label>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            placeholder="Altitude (metros)"
            v-model="alt"
            @input="formatAltitude"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Altitude (metros)</label>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            placeholder="Latitude"
            v-model="lat"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Latitude *</label>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            placeholder="Longitude"
            v-model="long"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Longitude *</label>
        </div>
      </div>
      <!-- <div class="col-sm-6">
        <Multiselect
          v-model="value"
          :columns="{ container: 12, label: 4, wrapper: 6 }"
          :placeholder="
            type == 'flow' ? 'Fluxo' : type == 'pressure' ? 'Pressão' : 'Ruído'
          "
          class="multiselect-custom"
          :options="tiposDispositivos"
        />
      </div> -->
      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            placeholder="Observações"
            autocomplete="off"
            v-model="obs"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Observações</label>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            autocomplete="off"
            class="form-control"
            placeholder="ID do Dispositivo"
            v-model="serial_number"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">ID do Dispositivo *</label>
        </div>
      </div>
    </div>
    <div class="form-check">
      <input
        class="form-check-input"
        v-model="active"
        type="checkbox"
        value=""
        id="flexCheckDefault"
      />
      <label class="form-check-label" for="flexCheckDefault"> Ativo </label>
    </div>
    <template #footer>
      <button @click="closeBasic" class="btn btn-danger">Cancelar</button>
      <button @click="saveEdit()" class="btn btn-filtrar">Salvar</button>
    </template>
  </Dialog>
</template>

<script>
import GroupbtnAdmin from "../components/groupbtnAdmin.vue";
import { store } from "../services/store";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { ref, onMounted } from "vue";
import { useLoading } from "vue-loading-overlay";
import Dialog from "primevue/dialog";
import Multiselect from "@vueform/multiselect";
import { useToast } from "primevue/usetoast";
import Toast from "primevue/toast";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import {
  listOneDevice,
  upDateComissioning,
  listClientComissioning,
} from "../services/crud.js";
import { updateAlt } from "../services/dashboard.js";
import { FilterMatchMode } from "primevue/api";
import moment from "moment";

const $loading = useLoading();

export default {
  components: {
    GroupbtnAdmin,
    Column,
    DataTable,
    Dialog,
    Toast,
    Multiselect,
    InputText,
    Dropdown,
  },
  data() {
    return {
      local_country: "",
      type: "",
      isSuper: store.type_user == 1 ? true : false,
      tiposDispositivos: [
        { label: "Vazão", value: "flow" },
        { label: "Pressão", value: "pressure" },
        { label: "Ruído", value: "noise" },
      ],
      resolucao: "",
      resolutionTable: [
        { value: 0.001, label: "1 litro" },
        { value: 0.01, label: "10 litros" },
        { value: 0.1, label: "100 litros" },
        { value: 1, label: "1000 litros" },
      ],
    };
  },
  setup() {
    const products = ref();
    const displayBasic = ref(false);
    const active = ref();
    const actives = ref(["Sim", "Não"]);
    const types = ref(["Pressão", "Ruído", "Vazão"]);
    const isFetching = ref(false); 

    const closeBasic = () => {
      displayBasic.value = false;
    };

    const getComissing = async () => {
      const loader = $loading.show({});

      if (isFetching.value) {
        loader.hide();
        return;
      }

      isFetching.value = true;

      let dataCom = {};
      let dataAux = [];
      let auxClient = {};

      auxClient = {};

      if (store.type_user != 1) {
        auxClient = {
          client_id: store.client_id,
        };
      }

      await listClientComissioning(auxClient)
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            for (let j = 0; j < response.data[i].devices.length; j++) {
              if (response.data[i].devices[j].commissioning_time) {
                dataCom = {
                  address_install:
                    response.data[i].address === null
                      ? " "
                      : `${response.data[i].address}, ${response.data[i].number}, ${response.data[i].neighborhood}, ${response.data[i].city} - ${response.data[i].state}, ${response.data[i].country}`,
                  meter_id:
                    response.data[i].meter === null
                      ? "Sem identificação"
                      : response.data[i].meter.id,
                  resolution:
                    response.data[i].meter != null
                      ? response.data[i].meter.setup.resolution
                      : "Sem hidrômetro",
                  device_id: response.data[i].devices[j].id,
                  type_sensor:
                    response.data[i].devices[j].type === "noise"
                      ? "Ruído"
                      : response.data[i].devices[j].type === "pressure"
                      ? "Pressão"
                      : "Vazão",
                  initial_display: response.data[i].devices[j].initial_display,
                  serial_number: response.data[i].devices[j].serial_number,
                  install_point_id: response.data[i].devices[j].install_point_id,
                  setup: response.data[i].devices[j].setup,
                  active:
                    response.data[i].devices[j].status == "ATIVADO"
                      ? "Sim"
                      : "Não",
                  commissioning_time: new Date(
                    response.data[i].devices[j].commissioning_time
                  ).toLocaleString("pt-br"),
                  lat: response.data[i].lat,
                  long: response.data[i].long,
                  country: response.data[i].country,
                  number: response.data[i].number,
                  zipcode: response.data[i].zipcode,
                  city: response.data[i].city,
                  state: response.data[i].state,
                  address: response.data[i].address,
                  neighborhood: response.data[i].neighborhood,
                  alt: response.data[i].alt,
                  obs: response.data[i].devices[j].obs === null ? "" : response.data[i].devices[j].obs,
                };
                dataAux.push(dataCom);
              }
            }
          }
          products.value = dataAux;
          loader.hide();
        })
        .catch((err) => {
          this.showErrorServer(err)
          loader.hide();
        })
        .finally(() => {
          isFetching.value = false;
          loader.hide();
        });
    };

    const selectedProduct1 = ref();
    const device_id = ref();
    const initial_display = ref();
    const meter_id = ref();
    const type_sensor = ref();
    const address_install = ref();
    const commissioning_time = ref();
    const lat = ref();
    const long = ref();
    const country = ref();
    const number = ref();
    const zipcode = ref();
    const city = ref();
    const state = ref();
    const address = ref();
    const neighborhood = ref();
    const alt = ref();
    const obs = ref();
    const serial_number = ref();
    const resolution = ref();
    const filters = ref({
      meter_id: { value: null, matchMode: FilterMatchMode.CONTAINS },
      serial_number: { value: null, matchMode: FilterMatchMode.CONTAINS },
      address_install: { value: null, matchMode: FilterMatchMode.CONTAINS },
      initial_display: { value: null, matchMode: FilterMatchMode.CONTAINS },
      commissioning_time: { value: null, matchMode: FilterMatchMode.CONTAINS },
      active: { value: null, matchMode: FilterMatchMode.EQUALS },
      type_sensor: { value: null, matchMode: FilterMatchMode.EQUALS },
    });

    onMounted(async () => {
      await getComissing();
    });

    const onRowSelect = (event) => {
      initial_display.value = event.data.initial_display;
      meter_id.value = event.data.meter_id;
      active.value = event.data.active === "Sim" ? true : false;
      device_id.value = event.data.device_id;
      lat.value = event.data.lat;
      long.value = event.data.long;
      country.value = event.data.country === null ? " " : event.data.country;
      number.value = event.data.number === null ? " " : event.data.number;
      zipcode.value = event.data.zipcode;
      city.value = event.data.city === null ? " " : event.data.city;
      state.value = event.data.state === null ? " " : event.data.state;
      address.value = event.data.address;
      neighborhood.value = event.data.neighborhood === null ? " " : event.data.neighborhood;
      alt.value = event.data.alt === null ? " " : event.data.alt;
      displayBasic.value = true;
      serial_number.value = event.data.serial_number;
      resolution.value = event.data.resolution;
      type_sensor.value = event.data.type_sensor;
      obs.value = event.data.obs === null ? " " : event.data.obs;
    };
    const toast = useToast();
    const showError = () => {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: "Campos Obrigatórios não foram preenchidos!",
        life: 2500,
      });
    };
    const showErrorServer = (err) => {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: err,
        life: 2500,
      });
    };
    const showsuccess = () => {
      toast.add({
        severity: "success",
        detail: " Dados de comissionamento alterados com sucesso! ",
        life: 2500,
      });
    };
    const toastSerialNumberError = () => {
      toast.add({
        severity: "error",
        detail:
          "Não existe nenhum dispositivo cadastrado com esse ID do Dispositivo.",
        life: 2500,
      });
    };
    return {
      products,
      toastSerialNumberError,
      selectedProduct1,
      onRowSelect,
      showError,
      showErrorServer,
      showsuccess,
      closeBasic,
      displayBasic,
      initial_display,
      meter_id,
      active,
      device_id,
      type_sensor,
      address_install,
      commissioning_time,
      lat,
      long,
      number,
      country,
      zipcode,
      city,
      state,
      address,
      neighborhood,
      alt,
      serial_number,
      resolution,
      getComissing,
      filters,
      actives,
      types,
      obs,
    };
  },
  methods: {
    saveEdit: async function () {
      let snDevice = {
        serial_number: this.serial_number,
      };
      const resp = await listOneDevice(snDevice).then((response) => {
        return response.data;
      });

      if (resp.length > 0) {
        const dataUpdate = {
          active: this.active,
          device_id: resp[0].id,
          install_point_id: this.dataForm.install_point_id,
          end_time: moment().format("YYYY-MM-DD HH:mm:ss"),
          setup: { "initial-display": this.initial_display },
          alt: this.alt,
          lat: this.lat,
          long: this.long,
          address: this.address,
          number: this.number,
          zipcode: this.zipcode,
          state: this.state,
          city: this.city,
          neighborhood: this.neighborhood,
          country: this.country,
          resolution: this.resolution,
          obs: this.obs,
        };

        if (
          !this.serial_number ||
          !this.serial_number.trim() ||
          (this.type_sensor === "flow" &&
            (!this.initial_display || !this.initial_display.trim()))
        ) {
          this.showError();
        } else {
          await upDateComissioning(dataUpdate)
            .then((response) => {
              if (response.status >= 200 && response.status <= 299) {
                this.upDateAlt(dataUpdate);
                this.showsuccess();
                this.closeBasic();
                setTimeout(() => {
                  this.getComissing();
                }, 1000);
              }
            })
            .catch((err) => this.showErrorServer(err));
        }
      } else {
        this.toastSerialNumberError();
      }
    },
    upDateAlt: async function (data) {
      if (data) {
        const payload = {
          id: data.install_point_id,
          lat: data.lat,
          long: data.long,
          address: data.address,
          number: data.number,
          zipcode: data.zipcode,
          state: data.state,
          city: data.city,
          neighborhood: data.neighborhood,
          country: data.country,
          alt: this.formattedAltitude,
        };

        try {
          await updateAlt(payload);
          this.getComissing();
        } catch (error) {
          this.showErrorServer(error);
        }
      }
    },
    formatAltitude(event) {
      let value = event.target.value;
      value = value.replace(/[^0-9.]/g, "");

      const parts = value.split(".");
      if (parts.length > 2) {
        value = parts[0] + "." + parts.slice(1).join("");
      } else if (parts.length === 2) {
        value = parts[0] + "." + parts[1].slice(0, 2);
      }

      this.alt = value;
    },
  },
  watch: {
    selectedProduct1: function (val) {
      const dataF = {
        active: val.active,
        device_id: val.device_id,
        meter_id: val.meter_id,
        install_point_id: val.install_point_id,
        setup: val.setup,
        alt: val.alt,
        lat: val.lat,
        long: val.long,
        type_sensor: val.type_sensor,
      };
      this.dataForm = dataF;
    },
  },
  mounted() {
    this.local_country = store.local_country;
  },
  computed: {
    formattedAltitude() {
      const altAsString = String(this.alt);
      return parseFloat(altAsString.replace(",", ".")) || 0;
    },
  },
};
</script>

<style scoped>
.form-check-input {
  margin-right: 0.5rem;
}
.btn-login {
  border-color: #6eff94;
  background-color: #6eff94;
  color: black;
  line-height: 2rem;
  font-size: 1.1rem;
  margin: 1rem;
  width: 8rem;
  font-weight: 450;
}
.form-check {
  display: flex;
  justify-content: center;
}
h2 {
  margin: 1rem;
}
.btn-outline-success {
  border-color: #6eff94;
  background-color: white;
  color: black;
}
.btn-outline-success:hover {
  border-color: #6eff94;
  background-color: #6eff94;
  color: black;
}
.btn-check:checked + .btn-outline-success {
  background-color: #6eff94;
  border-color: #6eff94;
  color: black;
}
.groupCadastros {
  text-align-last: center;
}
.dropdown-item {
  padding: 0rem 0rem;
}
.card {
  padding: 0rem;
  box-shadow: 0px 4px 19px 4px rgb(0 0 0 / 20%);
  margin: 1rem;
}
.btn-filtrar {
  border-color: #6eff94;
  background-color: #6eff94;
  color: black;
  margin: 1rem 0rem;
  width: 95%;
}
.input-group > .form-control,
.input-group > .form-select {
  background-color: #e8f0fe;
  color: black;
  line-height: 3rem;
  margin: 0rem 0.2rem;
}
.input-group {
  margin: 1rem 0rem;
}
.form-floating {
  margin: 1rem 0rem;
}

.form-floating > .form-control,
.form-floating > .form-select {
  background-color: #e8f0fe;
  color: black;
  line-height: 3rem;
  margin: 0rem 0.2rem;
}
.multiselect {
  height: 3.9rem;
  margin: 1rem 0rem;
  background: #e8f0fe;
}
.multiselect-placeholder {
  color: black !important;
}
input::placeholder {
  color: black;
}
.input-group-text {
  background-color: #37393c;
  padding: 0.375rem 1.2rem;
}
.block {
  pointer-events: none;
}
.serial-number {
  display: flex;
  justify-content: center;
  align-self: center;
}
.btn-router {
  line-height: 2rem;
  width: 100%;
}
@media screen and (max-width: 500px) {
  .btn-group {
    display: block;
  }
  .head-btn {
    display: block;
  }
}
@media screen and (min-width: 501px) {
  .head-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>

<style lang="scss" scoped>
::v-deep(.paginator-custom) {
  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #6eff94;
    border-color: #e3f2fd;
    color: #495057;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #495057;
    background: #6eff94;
  }
  .p-datatable .p-datatable-tbody > tr.p-highlight {
    background: #6eff94;
    color: #495057;
  }
}
</style>
<style lang="scss" scoped>
::v-deep(.multiselect-custom) {
  .multiselect-placeholder {
    color: black;
  }
}
</style>